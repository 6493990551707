import { getRatingService, getRatingLab } from "@/common/lib/api";
const defaultState = {
  labRate: null,
  serviceRate: null
};
export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  mutations: {
    SET_LAB_RATE(state, rate) {
      state.labRate = rate;
    },
    SET_SERVICE_RATE(state, rate) {
      state.serviceRate = rate;
    }
  },
  actions: {
    async getLabRate(_ref, address) {
      let {
        commit
      } = _ref;
      const rate = await getRatingLab(address);
      commit("SET_LAB_RATE", rate);
      return rate;
    },
    async getServiceRate(_ref2, id) {
      let {
        commit
      } = _ref2;
      const rate = await getRatingService(id);
      commit("SET_SERVICE_RATE", rate.data);
      return rate;
    }
  },
  getters: {
    getLabRate(state) {
      return state.labRate;
    },
    getServiceRate(state) {
      return state.serviceRate;
    }
  }
};