import Vue from "vue";
import Vuetify from "vuetify";
Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#C400A5",
        secondary: "#5640A5"
      },
      dark: {
        primary: "#C400A5",
        secondary: "#5640A5"
      }
    }
  }
});