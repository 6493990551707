//
//
//
//
//
//
//
//
//
//
//
//

import { heartBeatIcon } from "@debionetwork/ui-icons";
export default {
  name: "LoadingScreen",
  data: () => ({
    heartBeatIcon
  })
};