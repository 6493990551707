const defaultState = {
  selectedData: null,
  selectedAnalysisSerivice: null,
  loadingProgress: null,
  cancelUpload: null
};
export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  mutations: {
    SET_SELECTED_DATA(state, data) {
      state.selectedData = data;
    },
    SET_SELECTED_SERVICE(state, data) {
      state.selectedAnalysisSerivice = data;
    },
    SET_LOADING_PROGRESS(state, data) {
      state.loadingProgress = data;
    },
    SET_CANCEL_UPLOAD(state, data) {
      state.cancelUpload = data;
    }
  },
  actions: {
    async getData(_ref, data) {
      let {
        commit
      } = _ref;
      commit("SET_SELECTED_DATA", data);
    },
    async getSelectedAnalysisService(_ref2, data) {
      let {
        commit
      } = _ref2;
      commit("SET_SELECTED_SERVICE", data);
    },
    async getLoadingProgress(_ref3, data) {
      let {
        commit
      } = _ref3;
      commit("SET_LOADING_PROGRESS", data);
    },
    async setCancel(_ref4, data) {
      let {
        commit
      } = _ref4;
      commit("SET_CANCEL_UPLOAD", data);
    }
  }
};