//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cableErrorIcon } from "@debionetwork/ui-icons";
import { mapState, mapActions } from "vuex";
import { generalDebounce } from "@/common/lib/utils";
import NoAccessMobile from "@/views/NoAccessMobile";
import LoadingScreen from "@/views/LoadingScreen";
export default {
  name: "App",
  components: {
    NoAccessMobile,
    LoadingScreen
  },
  data: () => ({
    cableErrorIcon,
    address: "",
    isMobileDevice: false,
    showModalError: false
  }),
  computed: {
    ...mapState({
      substrateApi: state => state.substrate.api,
      substrateIsConnected: state => state.substrate.isConnected,
      isLoadingSubstrateApi: state => state.substrate.isLoadingApi
    })
  },
  watch: {
    $route(val) {
      this.formatTitle(val);
    },
    substrateIsConnected(val) {
      if (!val) this.showModalError = true;else this.showModalError = false;
    }
  },
  mounted() {
    document.title = "DeBio Network";
    this.formatTitle(this.$route);
    const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Apple Safari|Safari/i;
    this.handleChangeDevices(mobileDevices, window.innerWidth);
    const handleResize = generalDebounce(() => {
      this.handleChangeDevices(mobileDevices, window.innerWidth);
    }, 150);
    window.addEventListener("resize", handleResize);
  },
  methods: {
    ...mapActions({
      initWeb3: "web3Store/initWeb3"
    }),
    formatTitle(val) {
      var _parentRoute$meta;
      if (!val.meta.pageHeader) return;
      const routeChildren = this.$router.options.routes.find(route => /^\/genetic-analyst/.test(route.path)).children;
      const parentRoute = routeChildren.find(route => {
        var _val$meta;
        return route.name === ((_val$meta = val.meta) === null || _val$meta === void 0 ? void 0 : _val$meta.parent);
      });
      const formatedTitle = parentRoute !== null && parentRoute !== void 0 && (_parentRoute$meta = parentRoute.meta) !== null && _parentRoute$meta !== void 0 && _parentRoute$meta.pageHeader ? parentRoute.meta.pageHeader : val.meta.pageHeader;
      document.title = "DeBio Network | ".concat(formatedTitle);
    },
    handleChangeDevices(device, width) {
      if (device.test(navigator.userAgent) && width <= 768) this.isMobileDevice = true;else this.isMobileDevice = false;
    }
  }
};