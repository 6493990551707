// NOTE: PLEASE DON'T USE THIS UTIL FUNCTION FOR SUBSTRATE
let timeout;
export const generalDebounce = function (fn, delayTime) {
  let root = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(root ? root : this, args), delayTime);
  };
};