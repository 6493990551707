/* eslint-disable camelcase */
import apiClientRequest from "@/common/lib/api";
export const getNotifications = async _ref => {
  let {
    to_id,
    end_block,
    from
  } = _ref;
  const {
    data
  } = await apiClientRequest.get("/notification/".concat(to_id), {
    params: {
      start_block: 0,
      end_block,
      role: "GA",
      from
    }
  });
  return data;
};
export const setReadNotification = async ids => {
  const {
    data
  } = await apiClientRequest.put("/notification/set-read-many/", {
    ids
  });
  return data;
};
export const setBulkReadNotification = async to_id => {
  const {
    data
  } = await apiClientRequest.put("/notification/set-read/".concat(to_id));
  return data;
};