//
//
//
//
//
//
//
//
//

import { debioFullIcon } from "@debionetwork/ui-icons";
export default {
  name: "NoAccessMobile",
  data: () => ({
    debioFullIcon
  })
};