import apiClientRequest from "@/common/lib/api";
export const getRatingService = async id => {
  const {
    data
  } = await apiClientRequest.get("/rating/service/".concat(id));
  return data;
};
export const getRatingLab = async address => {
  const {
    data
  } = await apiClientRequest.get("/rating/lab/".concat(address));
  return data;
};